import moment from 'moment-timezone';
import _, { findIndex, sumBy } from 'lodash';
import ActivityType from '../../../../shared/core/activity/domain/ActivityType';
import { isWeekEnd } from '../../../../shared/utils/dateTime/dateTimeUtils';
import isDefined from '../../../../shared/utils/isDefined';
import getCodesAccordingToUserSpecialty from '../../../../utils/dataFetchers';
import findExpiredCode from './lumpsumCodeExpirationDate';
import {
  EVAQ_MANAGEMENT_CODES,
  EVAQ_CODES,
  EVAQ_OVERTIME_CODES
} from 'shared/periods/lumpSum/partialRate/partialRateUtils';
import LUMPSUM_DISPENSARY_AREA_MAP from 'shared/ramq/domainValues/lumpsumDispensaryAreas';

export const getCovidCodes = () => {
  const codesAccordingToUserSpecialty = getCodesAccordingToUserSpecialty(ActivityType.LUMP_SUM).find(
    (category) => category.id === 'COVID'
  );
  return (codesAccordingToUserSpecialty && codesAccordingToUserSpecialty.items.map((item) => item.code)) || [];
};

export const LUMPSUM_CATEGORY_CODE_PROHIBITED_TO_BE_BILLED_DURING_WEEK_END_AND_HOLIDAY = [
  'FAVORITES',
  'MEDICAL_ADMIN_ACTIVITY',
  'COMMITTEES_PROVIDED_BY_LAW',
  'TEACHING_ACTIVITY'
];

const getCodesForbiddenToBeBilledDuringWeekEndAndHoliday = () => {
  const specialtyRelatedForbiddenCodes = getCodesAccordingToUserSpecialty(ActivityType.LUMP_SUM)
    .filter((category) =>
      LUMPSUM_CATEGORY_CODE_PROHIBITED_TO_BE_BILLED_DURING_WEEK_END_AND_HOLIDAY.includes(category.id)
    )
    .reduce((acc, x) => acc.concat(x.items), [])
    .map((item) => item.code);

  return specialtyRelatedForbiddenCodes;
};

const isTimestampBetween = (timestamp, minHour, maxHour) => {
  const hour = moment(timestamp).hour();
  const minute = moment(timestamp).minute();

  return (
    (hour >= minHour && hour < maxHour) ||
    (minHour > maxHour && hour >= minHour) ||
    (minHour > maxHour && hour < maxHour) ||
    (hour === maxHour && minute === 0)
  );
};

export const errorMessage85020 = "Le code 85020 ne peut être facturé qu'entre 7h et 8h ou 15h et 17h";
export const validate85020 = ({ start, end }) => {
  if (
    !isActTimeIsInBound(start, end, [
      { lower: 7, upper: 8 },
      { lower: 15, upper: 17 }
    ])
  ) {
    return { code: errorMessage85020 };
  }

  return undefined;
};

export const errorMessage78127 = "Le code 078127 peut être facturé uniquement avec l'emplacement 02033";
export const ENFANT_JESUS_HOSPITAL_PLACE_NUMBER = '02033';
export const validate78127 = (day, place) => {
  if (place.number !== ENFANT_JESUS_HOSPITAL_PLACE_NUMBER) {
    return { code: errorMessage78127 };
  }

  return undefined;
};

const errorMessageCodeIsSupposedToBeBetween20hAnd8h = "Ce code ne peut être facturé qu'entre 20h et 8h";
const validateCodeIsBetween20hAnd8h = ({ start, end }) => {
  if (!isActTimeIsInBound(start, end, { lower: 20, upper: 8 })) {
    return { code: errorMessageCodeIsSupposedToBeBetween20hAnd8h };
  }

  return undefined;
};

export const errorMessageMinimumDuration = "Vous devez facturer un minimum d'une heure";
const isActDurationIsGreaterThanOrEqualToOneHour =
  () =>
  ({ code, start, end }) => {
    // MED-869, meetings can last less than an hour.
    if (!code || ['290336', '290338', 'xxx420'].includes(code)) return undefined;

    const startTimestamp = moment(start);
    const endTimestamp = moment(end);
    const timeDelta = Math.abs(startTimestamp.diff(endTimestamp, 'minutes'));

    if (timeDelta < 60) return { code: errorMessageMinimumDuration };

    return undefined;
  };

const isActTimeIsInBound = (start, end, boundaries) =>
  (Array.isArray(boundaries) ? boundaries : [boundaries])
    .map(({ lower, upper }) => {
      const isStartTimeIsBetweenBoundaries = isTimestampBetween(start, lower, upper);
      const isEndTimeIsBetweenBoundaries = isTimestampBetween(end, lower, upper);

      return isStartTimeIsBetweenBoundaries && isEndTimeIsBetweenBoundaries;
    })
    .includes(true);

const getValidationForCode = (code) => {
  switch (code) {
    case '085020':
      return validate85020;
    case '078127':
      return validate78127;
    case '290322-N':
      return validateCodeIsBetween20hAnd8h;
    case '290323-N':
      return validateCodeIsBetween20hAnd8h;
    default:
      return () => undefined;
  }
};

function getExpirationDateRelativeValidation({ code, date }) {
  const expiredCode = findExpiredCode(code);

  if (expiredCode && moment(date).isAfter(expiredCode.expirationDate)) {
    return {
      code: `Ce forfaitaire ne peut être facturé depuis le ${moment(expiredCode.expirationDate).format('Do MMMM YYYY')}`
    };
  }

  return undefined;
}

const isCodeOfLU238AndUsedAfterExpiration = ({ code, date }) => {
  if (!isDefined(code)) {
    return undefined;
  }

  const LU238Codes = [
    '290322',
    '290322-N',
    '290323',
    '290323-N',
    '290357',
    '290333',
    '290329',
    '290331',
    '290330',
    '290332',
    '290334',
    '290338',
    '290339',
    '290336',
    '290337'
  ];

  if (!LU238Codes.includes(code)) {
    return undefined;
  }

  if (moment(date).isSameOrBefore('2020-12-06')) {
    return undefined;
  }

  return { code: 'Ce code fait partie de la L/E 238 et ne peut être facturé après le 6 décembre' };
};

const validateDay = (place) => (day) =>
  getValidationForCode(day.code)(day, place) ||
  getExpirationDateRelativeValidation(day) ||
  isActDurationIsGreaterThanOrEqualToOneHour(day.code)(day) ||
  allowedToBeBilledDuringThisDay(place)(day) ||
  isCodeOfLU238AndUsedAfterExpiration(day) ||
  validateDurationMadeUpOfWholeHoursForEVAQCodes(day) ||
  validateRemoteConsultation(day);

const yearMonthDayFormat = ({ date }) => moment(date).format('YYYY-MM-DD');

// eslint-disable-next-line max-len
export const errorMessageLimitExceededByDayForCovidCodes =
  'Vous ne pouvez pas facturer plus de 12 heures par jour pour les codes en rapport avec la COVID-19';
const getDaysExceededTimeLimit = (periods) => {
  const { date } = periods[0];
  const hours = sumBy(periods, ({ code, start, end }) => {
    if (getCovidCodes().includes(code)) {
      const startMoment = moment(start);
      const endMoment = moment(end);

      if (startMoment > endMoment) {
        endMoment.add(1, 'days');
      }

      return Math.abs(startMoment.diff(endMoment, 'hours'));
    }

    return 0;
  });

  if (hours > 12) {
    return date;
  }

  return undefined;
};

const allowedToBeBilledDuringThisDay =
  (place) =>
  ({ code, date }) => {
    if (!code) {
      return undefined;
    }

    const dayDate = moment(date).format('Y-MM-DD');
    const placeDaysOff = place?.daysOff || [];

    if (!isWeekEnd(date) && isDefined(place) && !placeDaysOff.includes(dayDate)) {
      return undefined;
    }

    if (getCodesForbiddenToBeBilledDuringWeekEndAndHoliday().includes(code)) {
      return { code: `${code} ne peut être facturé durant cette journée` };
    }
  };

export const validateLumpSum = (values) => {
  const errors = {};
  const daysError = values.days.map(validateDay(values.place));

  _(values.days)
    .groupBy(yearMonthDayFormat)
    .map(getDaysExceededTimeLimit)
    .map((date) => findIndex(values.days, ['date', date]))
    .filter((index) => index >= 0)
    .forEach((index) => {
      daysError[index] = { code: errorMessageLimitExceededByDayForCovidCodes };
    });

  if (daysError.some((err) => err !== undefined)) {
    errors.days = daysError;
  }

  return errors;
};

const validateDurationMadeUpOfWholeHoursForEVAQCodes = ({ code, end, start }) => {
  const evaqCodes = [...EVAQ_MANAGEMENT_CODES, ...EVAQ_CODES, ...EVAQ_OVERTIME_CODES];

  if (!evaqCodes.includes(code)) return;

  const startMoment = moment(start);
  const endMoment = moment(end);
  const duration = endMoment.diff(startMoment, 'minutes');

  if (duration % 60 === 0) return;

  return { code: 'Ce code est payé pour un temps de service continue de 60 min, SVP changer les heures' };
};

const validateRemoteConsultation = ({ activityArea, originLocationOfRemoteConsultation }) => {
  if (activityArea !== LUMPSUM_DISPENSARY_AREA_MAP.remote) return;

  if (!originLocationOfRemoteConsultation) return { activityArea: 'Le lieu de consultation est requis' };

  if (originLocationOfRemoteConsultation.type === 'physical' && !originLocationOfRemoteConsultation.place) {
    return { activityArea: 'Le lieu de consultation est requis' };
  }

  if (originLocationOfRemoteConsultation.type === 'geographical' && !originLocationOfRemoteConsultation.postalCode) {
    return { activityArea: 'Le code postal est requis' };
  }
};
